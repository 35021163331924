<template>
    <div>
        <template v-if="$store.state.formcycle == '1'">
            <br>
            <div class="card js-card">
                <div class="card__title-row card__title-row--highlight">
                    <div class="card__title__left">{{ $t("Formcycle_header") }}</div>
                    <div class="card__title-row__right">
                        <span class="card__action-button card__action-button--bookmark btn btn--unstyled"><i aria-label="Bookmark" class="icon icon-rating-star" /></span>
                    </div>
                </div>

                <div class="card__content-row">
                    <div class="row">
                        <span class="formcycleHint">{{ $t('Formcycle_Ausfall') }}</span>
                    </div>
                </div>

            </div>
        </template>
        <template v-else>
            <div id="formcycle">
            </div>
        </template>
    </div>
</template>

<script>
// import $ from 'jquery';
import hop from '../../hop_connection';
import axios from "axios";

var dialog = null;
var gbnr = "";
var ghopUrl = "";
export default {
    props: ['bnr'],
    data() {
        return {
            status: false
        }
    },
    methods: {
        getUrlParameter: function (sParam) {
            var sPageURL = window.location.search.substring(1);
            var sURLVariables = sPageURL.split('&');
            var sParameterName, i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        },
        openURLDialog: function (bnr,lng) {
            // const url = "https://formulare.leipziger-messe.de/frontend-server/form/provide/2730?open&bnr=" + bnr  +"&lang=" + lng;
            const url = "https://formular2.leipziger-messe.de/frontend-server/form/provide/205/?lang=" + lng + "&bnr="+ bnr
            const width = 620;
            const height = 630;
            const left = (screen.width / 2) - (width / 2);
            const top = (screen.height / 2) - (height / 2);
            gbnr = bnr;
            dialog = window.open(url, 'blank_', `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no,status=no,location=no,menubar=no,toolbar=no`);
            try {
                ghopUrl = hop.getNotesURL();
            } catch (error) {
                console.error(error);
            }
        },

        async checkStatus() {
      //      this.$store.commit("fstatus", "ok");
            try {                
    //            console.log("gHopUrl=" + ghopUrl);
             //   let url = "https://asterix.leipziger-messe.de/test/webHotel.nsf/cmd?openAgent&cmd=checkbnr&bnr=" + gbnr;//hop.getHOPUrl() + "&checkbnr=" + bnr;
                 let url = "https://lemmon.leipziger-messe.de/LM/QAs/webhotel.nsf/cmd?openAgent&cmd=checkbnr&bnr=" + gbnr;//hop.getHOPUrl() + "&checkbnr=" + bnr;
                const response = await axios.get(url);
                // console.log("url=" + url)
         //       console.log("resonse=" + response.data)
                let ret = response.data; 
                if (ret.status === 'ok') {

                    this.status = true;
                    this.$store.commit("fstatus", "ok");
                  //  console.log("OK")
                    dialog.close()
                } else {
                  //  console.log("NO")
                    setTimeout(this.checkStatus, 2000);
                }
            } catch (error) {
                console.error('Fehler bei der Anfrage:', error);
            }
        }


    },

    mounted() {
        let url = hop.getFormcyvleURL(); 
        let nr = '';
        if (this.bnr != '') {
            nr = this.bnr
        } else {
            nr = this.getUrlParameter("bnr")
        }
        let lng = this.$i18n.locale;
        this.openURLDialog(nr,lng)
        this.checkStatus();
    }
}
</script>

<style>
.formcycleHint {
    color: red;
}
</style>