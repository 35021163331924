<template>

  <div v-if="isLoaded" v-cloak>

    <hop_head />
    <div class="container">
      <br>
      <!-- Seite Übersicht ein Hotel, blockt den Rest -->
      <template v-if="$store.state.statusHotelInfo">
          <hop_hotelinfo />
      </template>

      <template v-else-if="$store.state.status == 'notprebooking'">
         <div class="alert alert--danger" role="alert">
            {{$t("timer_error")}}<br>
            <a href="javascript:" @click="newsearch" class="link link--icon link--icon--left">
              <i class="icon icon-farmers-market-vendor"></i> 
              <span>{{ $t("button_neueSuchanfrage") }}</span>
            </a>
         </div>
      </template>

      <template v-else-if="$store.state.status == 'vanotfound'">
         <div class="alert alert--info abstand" role="alert">
        {{ $t('va_geschlossen')}}<a href="mailto:hotelreservation@leipziger-messe.de">hotelreservation@leipziger-messe.de</a>
      <br>
     </div>

      </template>
      <template v-else-if="$store.state.statusGruppenFormular">
        <hop_GruppenFormular />
      </template>
      <template v-else>
        
        <!--###  AA - Suche / Eingabedaten  -->
        <!-- || $store.state.status == 'select' -->
        <template v-if="$store.state.status == 'search'">
          <hop_search2 v-if="testNewSearch" :key="keySearch"/>
          <hop_search v-if="testNewSearch==false" :key="keySearch" :isVALoaded="isVALoaded"/>
          <hop_search_button style="margin-top: 10px"/><br> 
        </template>
        <hop_toomanypeople v-if="$store.state.status == 'tooManyPeople'" />

        <!-- <template v-else>
          VA = ... TODO {{ $store.state.vaName }}
        </template> -->

        <!-- ### BB - Hotel Suchergebnisliste -->
        <template v-if="$store.state.status=='select'">
          <template v-if="$store.state.hotelSearchResult.hotels.length>0">
            <hop_result_vadata/>
           <!-- <br> -->
            <hop_sortresult style="margin-top:10px"/>
            <p style="margin-top: 10px">
              <small>{{ $t("result_info") }}</small>
            </p>
          </template>
          <hop_result_liste class="hotelListe" />
        </template>

        <!-- ## CC - Anzeige / Auswahl Hotel & Formular-Eingabedaten -->
        <template v-if="$store.state.status=='formular'">
          <!-- gewähltes Hotel -->
          <hop_reg_hotel /> 
          <!-- Eingabe Buchungsdaten / Personen -->
          <br>
          <hop_reg_user v-if="true"/>
        </template>

        <!-- ## DD - Abschluss Finale -->
        <template v-if="$store.state.status == 'final'">
          <hop_bookingfinal :status="formcycleStatus" /> 
          <hop_formcycle @formcycleStatus="handleMessage" v-if='SHOW_FORMCYCLE' :bnr="$store.state.bcode"/>
        </template>

        <!-- Kontakt -->
        <template>
          <hop_kontakt v-if="$store.state.status == 'kontakt'"/> 
        </template>

       </template>
    </div>

    <hop_footer></hop_footer>
    <!-- <hop_sidebar></hop_sidebar> -->
    <!-- <hop_themenswitch> </hop_themenswitch> -->
      <button class="btn btn--secondary scroll-top-button js-scroll-top" title="Zum Seitenanfang">
        <span class="sr-only">Zum Seitenanfang</span><i class="icon icon-arrow-up-1"></i>   
    </button>

    <!-- <img src="./assets/logo.png" /> -->
      <!-- <test_button/> -->
    <!-- {{test}} -->
  </div>
</template>

<style>
  @import "./assets/css/main.css";
</style>

<script> 
/*global LM,$ */

// AA - Suche / Eingabedaten 
import hop_search from "@/components/search/hop_search";
import hop_search2 from "@/components/search2/search";
import hop_search_button from './components/search/hop_search_button';
import hop_toomanypeople from './components/search/hop_tooManyPeople';

// BB - Hotel Suchergebnisliste
import hop_result_vadata from "./components/result/hop_result_vadata";
import hop_sortresult from './components/result/hop_sortresult'
import hop_result_liste from "./components/result/hop_result_liste";

// CC - Anzeige / Auswahl Hotel & Formular-Eingabedaten
import hop_reg_hotel from "./components/formular/hotel/hop_reg_hotel";
import hop_reg_user from "./components/formular/hop_reg_user";

// DD - Abschluss Finale
import hop_bookingfinal from "./components/final/hop_bookingfinal"
import hop_formcycle from './components/formcycle/hop_formcycle';

// Rest - Header, Footer usw.
import hop_head from "./components/html/hop_head";
import hop_footer from "./components/html/hop_footer";
import hop_kontakt from './components/html/hop_kontakt';
import hop_hotelinfo from './components/info/hop_hotelinfo';
import hop_GruppenFormular from './components/formular/hotel/hop_group_booking.vue';
import hop from './hop_connection';
import hop2 from '@/lib/hoptools'
import dev from '@/lib/devtools'

export default {
  mixins: [dev, hop],
name: "App",
  data() { 
    return {
      isVALoaded: false,
      testNewSearch: false, 
      SHOW_FORMCYCLE: true, //  Option um Fomcycle auszublenden, für Prod und Übergangsphase
      test : 0,
      keySearch: 1001, 
      isLoaded: false,
      hotels: [{ "name": "uhu-1" }, { "name": "uhu-2" }],
      formcycleStatus: false
    };
  },
  watch:{
isVALoaded: function(n,o){
  dev.logg("isVALoaded=>" + n)
}
  },
  computed: {
     hasHotelFound: function(){
       if(this.$store.state.hotelSearchResult.hotels == false){
         return false;
       }
       if(this.$store.state.hotelSearchResult.hotels.length) {
          return false;
       } else {
          return true;
          // this.$store.state.hotelSearchResult.hotels.length;
       }
    },
    showHotelSearchResult: function() {
      return  this.$store.state.status == 'ok' ? true: false;
    }
  },
  methods: {
    handleMessage(message) {
      this.formcycleStatus = message;
    },
    newsearch: function(){
      // this.$store.state.status='search'
      // $store.state.status='search'
      this.$store.commit('status', 'search')
    },
    getVAByName: function(name){
    let ret = {};
    // this.$store.state.veranstaltungen.find(va => va.name == name)
    this.$store.state.veranstaltungen.forEach(va => {
        if( va.name == name || va.nameen == name ){
            ret = va;
        }
    });
    return ret
}
  },
  mounted() {
// Wechsel zu Englisch erfolgt erst nach dem Laden, da sonst die Datumsfelder verrückt spielen
    this.$i18n.locale = "de"; 
    this.lngSelect = "DE"

    let styleguide = document.createElement('script')
      styleguide.setAttribute('src', 'main.js')
      document.head.appendChild(styleguide)

       let timer_counter = 0;
        let lm_timer = setInterval( () => {
        timer_counter++;
        // console.log("typeof LM = " + typeof LM);
        if( typeof LM !== 'undefined'){
              this.isLoaded = true;

              clearInterval(lm_timer);
          
            $(".js-datepicker").each(function() {
              let dp = new LM.Datepicker( $(this.$el), 'de' ); 
            });

            $(".js-header").each(function() {
                let d = new LM.Header($(this));
             });

            $('.js-footer').each(function () {
                new LM.Footer( $(this) );
            });

            $('.js-sorting').each(function () {
              new LM.Sorting( $(this) );
            });

            $('.js-accordion').each(function () {
              new LM.Accordion( $(this) );
            });
              
        }
      }  
      )

   },
  components: {
    hop_head,
    hop_search,
    hop_search_button,
    hop_toomanypeople,
    hop_result_liste,
    hop_reg_hotel,
    hop_reg_user,
    hop_result_vadata,
    hop_bookingfinal,
    hop_footer,
    hop_kontakt,
    hop_hotelinfo,
    hop_GruppenFormular,
    hop_sortresult,
    hop_formcycle,
    hop_search2,
    // test_button,
  }  
};
</script>

<style>
.hotelListe {
  /* border: solid 1px; */
  vertical-align: text-top;
}
</style>