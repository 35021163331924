// var $ = require('jquery');
/*global $ */
module.exports = {
    isLogging: function() {
        return false;
    },
    getFormcyvleURL: function() {
        return 'https://formular2.leipziger-messe.de/frontend-server/form/provide/205/?open';
        // return 'https://formular2.leipziger-messe.de/frontend-server/form/provide/3?xfc-height-changed-evt=true';
    },
    openCenteredWindow: function(url, height, width, name, parms) {
        // var left = Math.floor( (screen.width - width) / 2);
        // var top = Math.floor( (screen.height - height) / 2);
        var winParms = "top=" + "10" + ",left=" + "10" + ",height=" + height + ",width=" + width;
        if (parms) { winParms += "," + parms; }
        var win = window.open(url, name, winParms);
        if (parseInt(navigator.appVersion) >= 4) { win.window.focus(); }
        return win;
      },
    getUrlParameter: function (sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        var sParameterName, i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    },
    getURLPrintFormular: function(id, la) {
        var url = document.location.protocol + "//" + document.location.host +  this.getNotesURL() + "/print?openForm&id=" + id + "&la=" + la;
        return url;
    }, 
    getHotelOverviewURL: function (loc_) {
        let loc;
        if( loc_ == null || loc_ == undefined || loc_ == ''){
            loc = window.location;  
        } else {
            loc = loc_
        }
        let url = loc.protocol + "//" + loc.host;
        let bo = true;
        if (bo) {
            url = this.getNotesURL(loc.href) + "/cmd?openAgent";
            return url;
        }        

        if (this.isBundy()) {
            if (!this.isDomino()) {
                url += "/proxy";
            }
            url += this.getNotesURL(loc.href) + "/Webhotel.nsf";

        } else {
            if (!this.isDomino()) {
                url += "/proxy";
            }
            url += "/proxy/LM/QAs/webHotel.nsf";
            //        alert("TODO - getHotelOverviewURL / Asterix");
        }
        url += "/cmd?openAgent";
        return url;
    },
    getHOPUrl: function(href){
        return this.getNotesURL(href) + "/cmd?openAgent"
    },
    getNotesURL: function(href){
        let url = href;
        if(url == ''){
          url = window.location.href;
        }
        let path = '';
        if(url.indexOf( ".nsf" )>0) {
          path = url.substring( 0, url.indexOf( ".nsf" ) + 4 );
        } else {
        //   path = '/proxy/test/webHotel.nsf';
        path = '/proxy/LM/QAs/webhotel.nsf'
        }
        return path;
      },
    formatPreis: function (p) {
    var formatter = new Intl.NumberFormat('de-DE', {
            // style: 'currency',
            // currency: 'EUR',
            minimumFractionDigits: 2
        });
        return formatter.format(p) + " EUR";
     },
    /**
     *  timeRange: von - bis; z.B.: "11.05.2020 - 13.05.2020" 
     * gibt die Anzahl der gebuchten Tage zurück 
     */
    getBookingDays: function (timeRange) {
        var dat = timeRange;
        if (dat == "" || dat == undefined) {
            return 0;
        }
        var dr = dat.split(" - ");
        var s1 = dr[0];
        var dat1 = s1.split(".");
        s1 = dr[1];
        var dat2 = s1.split(".");

        var von = new Date(dat1[1] + "/" + dat1[0] + "/" + dat1[2]);
        var bis = new Date(dat2[1] + "/" + dat2[0] + "/" + dat2[2]);
        // console.log("von=" + von);
        // console.log("bis=" + bis);

        var dif = (bis.getTime() - von.getTime());
        // console.log("1. Dif=" + dif);
        dif = dif / (1000 * 60 * 60 * 24);
        return dif;
    },
    isBundy: function () {
        var typ = this.getUrlParameter('typ');
        
        if (this.isLogging()) { console.log("Typ = " + typ);}
        
        if(typ == "d1"){
            return true;
        }
        if (document.location.host.indexOf("asterix") >= 0) {
            return false;
        }
        if (document.location.host.indexOf("troubadix") >= 0) {
            return false;
        }
        if (document.location.host.indexOf("messe") >= 0) {
            return false;
        }
        if (document.location.host.indexOf("172.23") >= 0) {
            return false;
        }
        if (document.location.host.indexOf("172.24") >= 0) {
            return false;
        }
        return true;
    },

    loadVAs_OLD: function (url) {
        // url = hop.getVASAjaxURL() + "&cmd=vas";
        // console.log("$loadVAs: " + url);
        // var va = this.getUrlParameter("va");
        
        $.ajax({
            url: url,
            cache: false,
            success: function (data) {
                // try {
                    // console.log("vas wurden geladen --- ");
                var vas = $.parseJSON(data);
                // console.log(vas);
                return vas;
 
                // appHop.veranstaltungen = vas.vas;

            }
        });
    },

    isDomino: function () {
        // if (window.location.port == 80){
        //     console.log("ja Domino ===> " + window.location.port );
        //     return true; 
        // } else {
        //      return false;  
        // }
        return window.location.port == 80;
    },

    hello: function () {
        return "Hello 123";
    }
};
