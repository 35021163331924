<template>
    <div>
        <button 
            class="btn btn--primary" 
            v-on:click="click_searchHotel">
            {{ $t("search_startButton") }}
        </button>
        <br>
        <br>
        <hr>
        <h4 style="color: var(--color-brand-primary)"> {{ $t('gruppe_Buttonhinweis') }} </h4>
        <button 
            class="btn btn--primary" 
            v-on:click="click_Gruppenbuchung">
            {{ $t("button_Gruppenbuchung") }}
        </button>

        <!-- <a  @click="click_Gruppenbuchung" href="javascript:console.log('')" class="link link--icon">
            <i class="icon icon-farmers-market-vendor"></i> 
            <span>{{ $t("button_Gruppenbuchung") }}</span>
        </a> -->
        <br><br>
    </div>
</template>

<script>
import Vue from 'vue';
import con from '../../hop_connection';
import dev from '@/lib/devtools'
import hop from '@/lib/hoptools'
import axios from 'axios'

export default {
    mixins: [dev, hop],
    props: {
    },
    computed: {
    },
    mounted(){
    //    console.log("searchButton mounted");
       if( this.$store.state.isTest ) {
            setTimeout( () => {
                if(this.$store.state.veranstaltungen.length>0){
                this.click_searchHotel();
            }
            }, 1000);
       }        
    },
    methods: {
        click_Gruppenbuchung: function() {
            this.$store.commit('vaNameSearch',this.$store.state.vaName)
            this.$store.commit('vaStartDateSearch',this.$store.state.vaStartDate);
            this.$store.commit('vaEndDateSearch',this.$store.state.vaEndDate);
            this.$store.commit('vaIDSearch',this.$store.getters.getVAID);
            this.$store.commit('statusGruppenFormular', true);
        },
        click_searchHotel: function() {
 //           console.log("this.$store.getters.getCountZimmer=" + this.$store.getters.getCountZimmer)
            if( this.$store.getters.getCountZimmer >5 ){
                 this.$store.commit('status', 'tooManyPeople');
                 return;
            }
            this.$store.commit('vaNameSearch',this.$store.state.vaName)
            this.$store.commit('vaStartDateSearch',this.$store.state.vaStartDate);
            this.$store.commit('vaEndDateSearch',this.$store.state.vaEndDate);
            this.$store.commit('vaIDSearch',this.$store.getters.getVAID);
            this.$store.commit('dzSearch',this.$store.state.dz);
            this.$store.commit('ezSearch',this.$store.state.ez);
            // this.$store.commit('vaObjSearch',this.$store.state.vaObj);
            
            let url = con.getNotesURL(window.location.href) + "/cmd?openAgent&cmd=hotelsfree";
            url += "&z=" + this.$store.getters.getCountZimmer;
            url += "&va=" + this.$store.getters.getVAID;
            url += "&an=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaStartDate));
            url += "&ab=" + dev.getDateStringDE(dev.getDate(this.$store.state.vaEndDate));
            url += "&ez=" + this.$store.state.ez;
            url += "&dz=" + this.$store.state.dz;

            console.log("url=" + url)

            // console.log("getHotelOverviewURL => " + url);
        // console.log("url=") + url;
            axios.get(url).then( (resp) => {    
                // resp.data.hotels.forEach((item) => {
                //     item.isCaravan  = false;
                //   });
  //            resp.data.hotels[0].isCaravan = true
                // console.table(resp.data);
                // console.log(resp.data.hotels[0].name + resp.data.hotels[0].isCaravan);
                // Vue.nextTick(() => {
                    this.$store.commit('hotelSearchResult',resp.data)
                    this.$store.commit('status', 'select')
                //  });
            });

        }
    }
};
</script>

<style>
hr {
  border: 1px solid var(--color-brand-primary);
  border-top: solid var(--color-brand-primary-light);
}
</style>
